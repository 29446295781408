import NavigationBar from "./components/NavigationBar/NavigationBar"
import WelcomeSection from './components/WelcomeSection/WelcomeSection'
import AboutSection from './components/AboutSection/AboutSection'
import ServicesSection from './components/ServicesSection/ServicesSection'
// import OurGamesSection from "./components/OurGamesSection/OurGamesSection"
import AdsPageSection from "./components/adsPageSection/AdsPage"
// import ReviewsSection from './components/ReviewsSection/ReviewsSection'
// import DownloadSection from './components/DownloadSection/DownloadSection'
import Footer from "./components/Footer/Footer"
import './index.css'
import ScrollToTop from "./components/ScrollToTop/scrollToTop"

import {createBrowserRouter, RouterProvider} from 'react-router-dom'

import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage'
import ContactUsPage from "./components/ContactUsSection/ContactUsPage"

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: 
      <>
      <ScrollToTop />
      <NavigationBar/>
      <WelcomeSection />
      <AboutSection />
      <ServicesSection/>
      {/* <OurGamesSection /> */}
      <Footer />
      </>
    },
    {
      path: "/privacy-policy",
      element: 
      <>
      <ScrollToTop />
      <NavigationBar />
      <PrivacyPolicyPage />
      <Footer />
      </>
    },
    {
      path: '/contact-us',
      element:
      <>
      <ScrollToTop />
      <NavigationBar />
      <ContactUsPage />
      {/* <Footer /> */}
      </>
    },
    {
      path: "/app-ads.txt",
      element: <AdsPageSection />
    }
  ]);


  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
